import React from 'react';
import {
  baseStage,
  baseAPIUrl,
  AWS_UPLOADS_BUCKET,
  baseUploadsUrl,
  baseLibraryUrl,
  UTC_OFFSET,
  hasAvailableNews,
  newsHaveTags,
  DEFAULT_ALLOW_COMMENTS,
  MAKE_API_KEY,
  logo,
  clientName,
} from '../config';
import { COLOUR_BRANDING_APP, COLOUR_BRANDING_MAIN, COLOUR_BRANDING_OFF } from '../js/Colours';
import * as PlussWebview from '@plusscommunities/pluss-webview-web';
// import * as PlussCore from '../pluss-extension-web/pluss-core/src';
import { PlussCore } from './core';
// import * as PlussMaintenanceWebDev from '../pluss-extension-web/pluss-maintenance/src';
// import * as PlussNewsletterWebDev from '../pluss-extension-web/pluss-newsletter/src';
import * as PlussGroupsWeb from '@plusscommunities/pluss-circles-web-groups';
import * as PlussMaintenanceWeb from '@plusscommunities/pluss-maintenance-web';
import * as PlussNewsletterWeb from '@plusscommunities/pluss-newsletter-web';
import * as PlussContentManagerWeb from '@plusscommunities/pluss-content-manager-web';
import * as PlussCirclesWeb from '@plusscommunities/pluss-circles-web';
import * as PlussNewsletterWebTraining from '@plusscommunities/pluss-newsletter-web-training';
import * as PlussAppointmentsWeb from '@plusscommunities/pluss-appointments-web';

// List of extensions
const extensions = [PlussGroupsWeb, PlussMaintenanceWeb, PlussWebview, PlussNewsletterWeb, PlussContentManagerWeb, PlussCirclesWeb, PlussNewsletterWebTraining, PlussAppointmentsWeb];

const extensionViewWidgets = {};
const extensionViewFulls = {};
const extensionPreviewWidgets = {};
const extensionPreviewFulls = {};
const extensionPreviewGridIcons = {};
const extensionActivityTexts = {};
const extensionWidgetInfos = {};
let extensionWidgetOptions = [];
const extensionWidgetKeys = [];
const extensionFeatureInfo = [];
let extensionRoutes = [];
let extensionPermissions = [];
let extensionHiddenSections = [];
let extensionMoreSections = [];
const extensionAliases = [];
let extensionReducers = { ...PlussCore.Reducers };
let extensionScreens = {};
const extensionAnalytics = {};

// Configure environment settings for the core
const environment = {
  baseStage,
  baseAPIUrl,
  baseUploadsUrl,
  baseLibraryUrl,
  uploadBucket: AWS_UPLOADS_BUCKET,
  colourBrandingMain: COLOUR_BRANDING_MAIN,
  colourBrandingOff: COLOUR_BRANDING_OFF,
  colourBrandingApp: COLOUR_BRANDING_APP,
  defaultProfileImage:
    'https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/d6a654304734aca4f7ebb19249/profilepic.png',
  utcOffset: UTC_OFFSET,
  hasAvailableNews,
  newsHaveTags,
  defaultAllowComments: DEFAULT_ALLOW_COMMENTS,
  makeApiKey: MAKE_API_KEY,
  logo,
  clientName,
};
PlussCore.Config.init(environment);

// Configure extensions
extensions.forEach((e) => {
  const {
    key,
    activities,
    description,
    emptyText,
    widgetOptions,
    menu,
    addUrl,
    addPermission,
    singularName,
    routes,
    permissions,
    hiddenSections,
    moreSections,
    aliases,
    hiddenFromFeaturePicker,
    featurePickerInfo,
  } = e.Config;

  // Configure environment settings
  e.Config.init(environment);

  // Configure feature picker controls
  if (e.ViewWidget) extensionViewWidgets[key] = e.ViewWidget;
  if (e.ViewFull) extensionViewFulls[key] = e.ViewFull;
  if (e.PreviewWidget) extensionPreviewWidgets[key] = e.PreviewWidget;
  if (e.PreviewFull) extensionPreviewFulls[key] = e.PreviewFull;
  if (e.PreviewGrid) extensionPreviewGridIcons[key] = e.PreviewGrid;
  if (e.Analytics) extensionAnalytics[key] = e.Analytics;

  // Configure activity list
  if (activities) {
    activities.forEach((a) => {
      extensionActivityTexts[a] = e.ActivityText;
    });
  }

  // Configure reducers
  if (e.Reducers) extensionReducers = { ...extensionReducers, ...e.Reducers };

  // Configure screens
  if (e.Screens) extensionScreens = { ...extensionScreens, ...e.Screens };

  // Configurations
  extensionWidgetInfos[key] = { description: description || '', emptyText: emptyText || '' };
  if (widgetOptions && widgetOptions.length > 0) {
    extensionWidgetOptions = extensionWidgetOptions.concat(
      widgetOptions.map((o) => {
        return { key, ...o };
      }),
    );
  }
  extensionWidgetKeys.push(key);
  extensionFeatureInfo.push({
    key,
    addUrl: addUrl || '',
    addPermission: addPermission || '',
    singularName: singularName || '',
    hiddenFromFeaturePicker: hiddenFromFeaturePicker || false,
    featurePickerContent: e.FeaturePickerContent,
    featurePickerInfo,
    ...(menu || {}),
  });
  if (routes && routes.length > 0) extensionRoutes = extensionRoutes.concat(routes);
  if (permissions && permissions.length > 0) extensionPermissions = extensionPermissions.concat(permissions);
  if (hiddenSections && hiddenSections.length > 0) extensionHiddenSections = extensionHiddenSections.concat(hiddenSections);
  if (moreSections && moreSections.length > 0) extensionMoreSections = extensionMoreSections.concat(moreSections);
  extensionAliases.push({ key, aliases: aliases || [] });
});

const getExtensionViewWidget = (widget, renderDefault = () => {}) => {
  const ViewWidget = extensionViewWidgets[widget];
  return ViewWidget ? <ViewWidget key={widget} /> : renderDefault();
};

const getExtensionViewFull = (widget, page, renderDefault = () => {}) => {
  const ViewFull = extensionViewFulls[widget];
  return ViewFull ? <ViewFull key={widget} page={page} /> : renderDefault();
};

const getExtensionPreviewWidget = (widget, page, widgetTitle, backgroundColor, renderDefault = () => {}) => {
  const PreviewWidget = extensionPreviewWidgets[widget];
  return PreviewWidget ? (
    <PreviewWidget
      key={widget}
      page={page}
      widgetTitle={widgetTitle}
      backgroundColor={backgroundColor}
      titleClassName={'featurePickerPreview_tile_title featurePickerPreview_tile_title-widget'}
    />
  ) : (
    renderDefault()
  );
};

const getExtensionPreviewFull = (widget, page, widgetTitle, backgroundColor, options, renderDefault = () => {}) => {
  const PreviewFull = extensionPreviewFulls[widget];
  return PreviewFull ? (
    <PreviewFull
      key={widget}
      page={page}
      widgetTitle={widgetTitle}
      backgroundColor={backgroundColor}
      titleClassName={'featurePickerPreview_tile_title'}
      options={options}
    />
  ) : (
    renderDefault()
  );
};

const getExtensionPreviewGridIcon = (widget, colour, renderDefault = () => {}) => {
  const PreviewGrid = extensionPreviewGridIcons[widget];
  return PreviewGrid ? <PreviewGrid key={widget} colour={colour} /> : renderDefault();
};

const getActivityText = (data, classes, clickableClasses, highlightedClasses, renderDefault = () => {}) => {
  const ActivityText = extensionActivityTexts[data.Type];
  return ActivityText ? (
    <ActivityText data={data} classes={classes} clickableClasses={clickableClasses} highlightedClasses={highlightedClasses} />
  ) : (
    renderDefault()
  );
};

export {
  PlussCore,
  extensionWidgetInfos,
  extensionWidgetOptions,
  extensionWidgetKeys,
  extensionFeatureInfo,
  extensionRoutes,
  extensionPermissions,
  extensionHiddenSections,
  extensionMoreSections,
  extensionAliases,
  extensionReducers,
  extensionScreens,
  extensionAnalytics,
  getExtensionViewWidget,
  getExtensionViewFull,
  getExtensionPreviewWidget,
  getExtensionPreviewFull,
  getExtensionPreviewGridIcon,
  getActivityText,
};
